import {useState, useEffect} from 'react'
import {useLocation, Link} from 'react-router-dom'
import cn from 'classnames'
import { Sheet, SheetClose, SheetContent, SheetDescription, SheetFooter, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet"
import { X, Bell, LogOut  } from "lucide-react"
import { Button } from '@/components/ui/button'
import { Label, GreenLabel } from "@/components/ui/label"
import { getToken, removeToken } from "../../services/TokenService";
import { MySpinner } from '../ui/spinner'
import { useQuery } from "@tanstack/react-query";

import Axios from "@/components/lib/axios"

// css
import Style from './index.module.css'
// assets
import Nav01 from '@/assets/image/ico/ico-topnav-list01.svg'
import Nav02 from '@/assets/image/ico/ico-topnav-list02.svg'
import Nav03 from '@/assets/image/ico/ico-topnav-list03.svg'
import Nav04 from '@/assets/image/ico/ico-topnav-list04.png'
const Index = (mtype) => {
	const [isLoading, setIsLoading] = useState(true);
	const [inAppMessages, setInAppMessages] = useState([]);
	const [numOfInAppMessages, setNumOfInAppMessages] = useState(0);

	const getInAppMessages = async (e) => {
		const result = await Axios.get(`/api_message/in_app`);
		setNumOfInAppMessages(result.data.length);
		setInAppMessages([...result.data])

		setIsLoading(false);
	}

	useEffect(() => {
		getInAppMessages();
	  }, []);

	// useEffect(()=>{
	// 	const getNumberOfMessages = async () => {
	// 		const result = await Axios.get(`/api_message/in_app/num/40`);
	// 		console.log('[' + new Date().toUTCString() + '] ' + result.data.count)
	// 		return result.data.count;
	// 	}
	// 	const count = getNumberOfMessages();
	// 	setNumOfInAppMessages(count);
	// }, []);

	const logout = () => {
		//if(window.confirm("로그아웃 하시겠습니까?")){
			removeToken();
			alert("로그아웃 되었습니다.");
			//if((mtype.membertype === 'AD') || (mtype.membertype === 'JM') || (mtype.membertype === 'SM')){
			//	window.location.href="/admin";
			//}else{
				window.location.href="/";
			//}
		//}
	}

	const mypage = () => {
		if(mtype.membertype === 'RS'){
			window.location.href="/myinfo";
		}else{
			window.location.href="/admin/myinfo";
		}
	}

	const onCloseButtonClicked = async (message_id) => {
		setIsLoading(true);
		const result = await Axios.post(`/api_message/in_app/read/${message_id}`);
		console.log(result);
		if(result.data === "OK"){
			const result = await Axios.get(`/api_message/in_app`);
			setInAppMessages([...result.data])
			setNumOfInAppMessages(result.data.length);
		}

		setIsLoading(false);
	}

	return (
		<div className={Style.topnav}>
			<ul>
				<li>
				<Sheet>
					<SheetTrigger asChild>
						
						<button type="button">
							<div className='relative'>
								<Bell className='text-white w-8 h-8 mt-2'/>
								{
									isLoading
									?
									<MySpinner/>
									:
									numOfInAppMessages > 0 
									&& 
									<span className="bg-red-700 text-white text-sm rounded-full px-1 pr-1.6 absolute top-0 right-0-3 flex items-center justify-center">
										{numOfInAppMessages}
									</span>
								}
							</div>
						</button>
					</SheetTrigger>
					<SheetContent className="z-[401]">
						<SheetHeader>
							<Bell className='text-primary-foreground'/><div className='text-lg font-semibold text-primary-foreground'>알림</div>
						</SheetHeader>
						{
							isLoading
							?
							<MySpinner/>
							:
							inAppMessages.length > 0
							?
							<div className="flex flex-col gap-6 mt-5">
								{
									inAppMessages.map((message) => {
										return <div className="flex flex-col gap-4 p-4 pt-4 items-end border border-stone-200 rounded-lg bg-primary">
													<Button variant="sheet_icon" size="sheet_icon" className="w-6 h-6" onClick={()=>onCloseButtonClicked(message.id)}><X/></Button>
													<Label className="text-gray-900 font-bold self-start">
														{message.subject}
													</Label>
													<Label className="text-left leading-normal text-gray-900 self-start">
														{message.content}
													</Label>
													{
														message.link_url
														&&
														<SheetClose asChild><Link className="self-start" to={message.link_url}><Button variant="outline" className="border-none bg-transparent text-red-700">자세히 보기</Button></Link></SheetClose>
													}
												</div>
									})
								}
								<div className='h-6'></div>
							</div>
							:
							<div className='flex'>
								<Label className="text-lg mt-8 ml-6 text-red-700">메시지가 없습니다</Label>
							</div>
						}
						
					</SheetContent>
				</Sheet>
				</li>
				<li>
					<button type="button" onClick={mypage}><img src={Nav04}/></button>
				</li>
				<li>
					<button type="button" onClick={logout}><LogOut className='text-white w-8 h-8'/></button>
				</li>
			</ul>
		</div>

	)
}

export default Index
