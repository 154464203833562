import { createSlice } from '@reduxjs/toolkit';

export const globalSlice = createSlice({
  name: 'global',
  initialState: {
    mode: 'light',
  },
  reducers: {
    changeMode: (state, action) => {
      state.mode = action.payload;
    },
  },
});

export const { changeMode } = globalSlice.actions;

export const selectMode = state => state.global.mode;

export default globalSlice.reducer;
