import React, {useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom"
import axios from "axios";
import cn from 'classnames'
import { getToken } from "../../services/TokenService";

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import styled from "@emotion/styled";

// css
import Style from './index.module.css'
import CntList01 from '@/assets/image/ico/ico-cnt-list01.svg'
import CntList02 from '@/assets/image/ico/ico-cnt-list02.svg'
import CntList03 from '@/assets/image/ico/ico-cnt-list02.svg'
import CntList04 from '@/assets/image/ico/ico-cnt-list02.svg'
import Cmm from '@/assets/image/ico/ico-cmm.svg'
import Notice from '@/assets/image/ico/ico-noti.svg'
import More from '@/assets/image/ico/ico-arr-more.svg'
import ProductImg from '@/assets/image/etc/etc-product01.png'

export const StyleWrapper = styled.div`
  .fc td {
    
  }
  .fc-prev-button + button{display:none;}
  .fc-toolbar-title + button{display:none;}
  .fc-toolbar-title{color:#40EFC1;}
  .fc-toolbar-chunk{display:flex;justify-content:center;align-items:center;}
  .fc .fc-button-primary{}
  .fc-today-button{background:#40EFC1 !important;border-color:#40EFC1 !important;color:#000 !important;}
  .fc .fc-daygrid-day.fc-day-today{background: rgba(64, 239, 193, 0.22) !important;}
  .fc-theme-standard tr>td:first-child,
  .fc-theme-standard tr>td:nth-child(7){background: rgba(217, 217, 217, 0.16) !important;}
  .fc .fc-daygrid-day-number{color:#fff}
  .fc .fc-col-header-cell-cushion{color:#fff;}
  @media screen and (max-width: 1024px) {
  	.fc-header-toolbar{display:block;}
  	.fc-toolbar-chunk{margin:20px 0 0 0;}
  }
`

const Index = () => {
	const [boardList, setBoardList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [noticeList, setNoticeList] = useState([]);
	const [cmboardList, setCmBoardList] = useState([]);
	const [comcnt1, setComCnt1] = useState("0");
	const [comcnt2, setComCnt2] = useState("0");
	const [comcnt3, setComCnt3] = useState("0");
	const navigate = useNavigate();

	const hostname = window.location.hostname;
	const pathname = window.location.pathname;

	const getBoardList = async (e) => {
		//const resp = await (await axios.get(process.env.REACT_APP_API_ADMINHOUSELIST)).data; 
		//setBoardList(resp.list); 
		//setLoading(false);
		const token = getToken();
		
		if(token !== null){
			const local_token = JSON.parse(token);
			
			axios({
				method: 'post',
				url: process.env.REACT_APP_API_ADMINHOUSELIST,
				data:{
					company: 'A'+local_token.company,
				},
			}).then((res) => {
				setBoardList(res.data.list); 
				setLoading(false);
			}).catch((err) => {	
				console.log("에러", err);
			})
		}
	}

	const getNoticeList = async (e) => {
		//const resp = await (await axios.get(process.env.REACT_APP_API_NOTICE_LIST_MAIN)).data; 
		//setNoticeList(resp.list); 
		//const totalCnt = resp.cnt;
		const token = getToken();
		
		if(token !== null){
			const local_token = JSON.parse(token);
			
			axios({
				method: 'post',
				url: process.env.REACT_APP_API_NOTICE_LIST_MAIN,
				data:{
					company: 'A'+local_token.company,
				},
			}).then((res) => {
				setNoticeList(res.data.list); 
				const totalCnt = res.data.cnt;
			}).catch((err) => {	
				console.log("에러", err);
			})
		}
	}

	const getCmBoardList = async (e) => {
		const token = getToken();
		
		if(token !== null){
			const local_token = JSON.parse(token);

			axios({
				method: 'post',
				url: process.env.REACT_APP_API_COMMUNICATION_LIST_TOTAL_MAIN,
				mode: "cors",
				credentials: "same-origin",
				data:{
					company: 'A'+local_token.company,
				},
			}).then((res) => {
				setCmBoardList(res.data.list); 
				setComCnt1(res.data.pcnt1);
				setComCnt2(res.data.pcnt2);
				setComCnt3(res.data.pcnt3);
			}).catch((err) => {	
				console.log("에러", err);
			})
		}
	}

	const onHouseCreate = () => {
		navigate("/admin/house/register");
	}

	const loginchk = () => {
		const token = getToken();
		//console.log(token);
		if(token === ""){
			//if((pathname === '/admin')){
			//	window.location.href="/admin";
			//}else{
				window.location.href="/login";
			//}
		}
	}

	useEffect(() => {
		loginchk();
		setLoading(true);
		getBoardList();
		getNoticeList();
		getCmBoardList();
	}, []);

	return (
		<div className={Style.wrapper}>
			<div className={Style.titlebar}>
				<strong>ADMIN</strong>
				<span>주택관리 어드민</span>
				<button type={'button'} onClick={onHouseCreate}>주택계정 생성</button>
			</div>

			<ul className={Style.housingList}>
				{boardList.map((board) => (
				<li key={board.seq}>
					<a href={`/admin/house/view/${board.seq}`}>
						<div className={Style.stit}>{board.house_name}</div>
						<div className={Style.snum}>{board.house_id ? board.house_id : <br/>}</div>
						<div className={Style.spic} style={{backgroundImage:`url(${ProductImg})`}}></div>
						<div className={Style.addr}>{board.addr} {board.addr_detail}</div>
						<ul>
							<li>
								<div className={Style.key}>층수 / 호수</div>
								<div className={Style.value}>{board.floor}층 {board.hosu}호</div>
							</li>
							<li>
								<div className={Style.key}>보증금 / 임대료</div>
								<div className={Style.value}>0 / 0</div>
							</li>
						</ul>
						<ul>
							<li>
								<div className={Style.key}>입주율</div>
								<div className={Style.value}>0%</div>
							</li>
							<li>
								<div className={Style.key}>7일이내 입금예정</div>
								<div className={Style.value}>0명</div>
							</li>
							<li>
								<div className={Style.key}>연체자</div>
								<div className={Style.value}>0명</div>
							</li>
						</ul>
					</a>
				</li>
				))}	
				
			</ul>

			<section className={Style.cntWrap}>
				<ul>
					<li>
						<div className={Style.ico}><img src={CntList01} /></div>
						<div className={Style.key}>신규 계약자 수</div>
						<div className={Style.value}>0</div>
					</li>
					<li>
						<div className={Style.ico}><img src={CntList02} /></div>
						<div className={Style.key}>전체 거주자 수</div>
						<div className={Style.value}>0</div>
					</li>
					<li>
						<div className={Style.ico}><img src={CntList03} /></div>
						<div className={Style.key}>항목미정</div>
						<div className={Style.value}>-</div>
					</li>
					<li>
						<div className={Style.ico}><img src={CntList04} /></div>
						<div className={Style.key}>항목미정</div>
						<div className={Style.value}>-</div>
					</li>
				</ul>
			</section>

			<section className={Style.staticsWrap}>
				<div className={Style.chartWrap}>주택별 보증금/임대료 보증금 그래프. 대략적인 내용들이 필요해요</div>
				<div  className={Style.listWrap}>
					<ul>
						{/*}
						<li>
							<div className={Style.cate}>@장안생활</div>
							<div className={Style.name}>302호 이지혜</div>
							<div className={Style.age}>32/여</div>
						</li>
						<li>
							<div className={Style.cate}>@장안생활</div>
							<div className={Style.name}>302호 이지혜</div>
							<div className={Style.age}>32/여</div>
						</li>
						{*/}
					</ul>
				</div>
			</section>

			<section className={Style.latestWrap}>
				<div  className={Style.headerWrap}>
					<div className={Style.lt}>
						<img src={Cmm} />
						<strong>1:1 커뮤니케이션</strong>
						<ul>
							<li>접수 <span>{comcnt1}</span></li>
							<li>확인 <span>{comcnt2}</span></li>
							<li>처리완료 <span>{comcnt3}</span></li>
						</ul>
					</div>
					<div className={Style.rt}>
						<a href="/admin/communication">
							<span>더보기</span>
							<img src={More} />
						</a>
					</div>
				</div>
				<div  className={cn(Style.contWrap, Style.type01)}>
					<ul>
						{cmboardList.map((board) => (
							<li key={board.seq}>
								<a href={`/admin/communicationView/${board.seq}`}>
									<div className={Style.comm01}>{board.housename}</div>
									<div className={Style.comm02}>{board.hosu}호 {board.memname}</div>
									<div className={Style.cate}>[{board.gubun}]</div>
									<div className={Style.comm03}>{board.status}</div>
									<div className={Style.tit}>{board.subject}</div>
									<div className={Style.date}>{board.regdate}</div>
								</a>
							</li>
						))}
						{/*}
						<li>
							<a href="#">
								<div className={Style.comm01}>@장안생활</div>
								<div className={Style.comm02}>302호 이지혜</div>
								<div className={Style.cate}>[주거시설]</div>
								<div className={Style.comm03}>접수</div>
								<div className={Style.tit}>클럽내 공지사항 제목 클럽내 공지사항 제목 클럽내 공지사항 제목 클럽내 </div>
								<div className={Style.date}>23.01.12</div>
							</a>
						</li>
						<li>
							<a href="#">
								<div className={Style.comm01}>@장안생활</div>
								<div className={Style.comm02}>302호 이지혜</div>
								<div className={Style.cate}>[주거시설]</div>
								<div className={Style.comm03}>접수</div>
								<div className={Style.tit}>클럽내 공지사항 제목 클럽내 공지사항 제목 클럽내 공지사항 제목 클럽내 </div>
								<div className={Style.date}>23.01.12</div>
							</a>
						</li>
						<li>
							<a href="#">
								<div className={Style.comm01}>@장안생활</div>
								<div className={Style.comm02}>302호 이지혜</div>
								<div className={Style.cate}>[주거시설]</div>
								<div className={Style.comm03}>접수</div>
								<div className={Style.tit}>클럽내 공지사항 제목 클럽내 공지사항 제목 클럽내 공지사항 제목 클럽내 </div>
								<div className={Style.date}>23.01.12</div>
							</a>
						</li>
						{*/}
					</ul>
				</div>
			</section>

			<section className={Style.latestWrap}>
				<div  className={cn(Style.headerWrap, Style.t01)}>
					<div className={Style.lt}>
						<img src={Notice} />
						<strong>공지사항</strong>
					</div>
					<div className={Style.rt}>
						<a href="/admin/notice">
							<span>더보기</span>
							<img src={More} />
						</a>
					</div>
				</div>
				<div  className={Style.contWrap}>
					<ul>
						{noticeList.map((board) => (
							<li key={board.seq}>
								<a href={`/admin/NoticeView/${board.seq}`}>
									<div className={Style.cate}>[{board.notype}]</div>
									<div className={Style.tit}>{board.subject}</div>
									<div className={Style.date}>{board.regdate}</div>
								</a>
							</li>
						))}		
						{/*}
						<li>
							<a href="#">
								<div className={Style.cate}>[주거시설]</div>
								<div className={Style.tit}>클럽내 공지사항 제목 클럽내 공지사항 제목 클럽내 공지사항 제목 클럽내 </div>
								<div className={Style.date}>23.01.12</div>
							</a>
						</li>
						<li>
							<a href="#">
								<div className={Style.cate}>[주거시설]</div>
								<div className={Style.tit}>클럽내 공지사항 제목 클럽내 공지사항 제목 클럽내 공지사항 제목 클럽내 </div>
								<div className={Style.date}>23.01.12</div>
							</a>
						</li>
						<li>
							<a href="#">
								<div className={Style.cate}>[주거시설]</div>
								<div className={Style.tit}>클럽내 공지사항 제목 클럽내 공지사항 제목 클럽내 공지사항 제목 클럽내 </div>
								<div className={Style.date}>23.01.12</div>
							</a>
						</li>
						{*/}
					</ul>
				</div>
			</section>

			<div className={Style.titlebar}>
				<strong>Calendar</strong>
			</div>
			<div className={Style.descbar}>Events</div>

			<div className={Style.calendarRela}>
				<div className={Style.side}>
					<ul>
						<li className={Style.status01}>
							<div className={Style.mdesc}>-</div>
							<div className={Style.mdate}>2023.07.24</div>
						</li>
						<li className={Style.status02}>
							<div className={Style.mdesc}>-</div>
							<div className={Style.mdate}>2023.07.24</div>
						</li>
					</ul>
				</div>
				<div className={Style.acticle}>
					<StyleWrapper>
						<FullCalendar
							plugins={[dayGridPlugin,timeGridPlugin,interactionPlugin, listPlugin ]}
							initialView={'dayGridMonth'}
							headerToolbar={
								{
									left: "today",
									center: 'prev, title, next',
									right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
								}
							}
							height={"85vh"}
							//dateClick={this.dateClick}
							events={[{title:'판매건수 : 23건', date:'2023-05-11',},{title:'판매건수 : 23건',date:'2023-05-13',}]}
						/>
					</StyleWrapper>


				</div>
			</div>

		</div>
	)
}

export default Index
