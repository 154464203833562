import axios from "axios";
import { getToken } from "@/services/TokenService";

const Axios = axios.create({
  baseURL: "https://siso.live:5010/",
  withCredentials: true
});

if(getToken())
  Axios.defaults.headers.common['Authorization'] = "Bearer " + JSON.parse(getToken()).access_token;

export default Axios;

// baseURL: "https://siso.live:5010/",