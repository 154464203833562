import { ColorRing } from 'react-loader-spinner';

export const MySpinner = () => 
    <div className='flex justify-center items-center h-screen'>
        <div style={{zIndex:'500', position: 'absolute', width: '80px', height: '80px', top: 'calc( 50% - 100px )', left: 'calc( 50% )'}}>
                        <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="blocks-loading"  
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}/></div>
    </div>
    