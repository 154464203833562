import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva } from "class-variance-authority";

import { cn } from "@/components/lib/utils"

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-accent text-primary-foreground text-base hover:bg-accent/70 border-0 hover:text-foreground",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border-1 bg-background hover:bg-accent hover:text-accent-foreground text-primary text-base",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "border-none bg-background hover:bg-accent hover:text-accent-foreground text-primary text-base",
        intable: "border-none bg-table-row-bg hover:bg-accent hover:text-accent-foreground text-primary text-base",
        link: "text-primary underline-offset-4 hover:underline",
        header: "border-none bg-my-blue hover:bg-accent hover:text-accent-foreground text-primary text-base",
        icon: "border-none bg-transparent text-accent-foreground hover:text-foreground  text-base",
        sheet_icon: "border-none bg-transparent text-gray-400 hover:text-red-950 text-base",
        big: "bg-accent text-primary-foreground text-xl hover:bg-accent/70 border-0 hover:text-foreground",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 px-0 py-2",
        sheet_icon: "h-5 w-5 px-1 py-1",
        big: "h-12 px-5 py-3",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

const Button = React.forwardRef(({ className, variant, size, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : "button"
  return (
    (<Comp
      className={cn(buttonVariants({ variant, size, className }))}
      ref={ref}
      {...props} />)
  );
})
Button.displayName = "Button"

export { Button, buttonVariants }
