import {useState, useEffect} from 'react'
import {useLocation, Link} from 'react-router-dom'
import axios from "axios";
import cn from 'classnames'
import { getToken } from "../../services/TokenService";

// css
import Style from './header.module.css'
// assets
import Menu from '@/assets/image/ico/ico-menu.png'
import Home from '@/assets/image/ico/ico-home.png'
import Nav01 from '@/assets/image/ico/ico-nav-list01.png'
import Nav02 from '@/assets/image/ico/ico-nav-list02.png'
import Nav03 from '@/assets/image/ico/ico-nav-list03.png'
import Nav04 from '@/assets/image/ico/ico-nav-list04.png'
import Nav05 from '@/assets/image/ico/ico-nav-list05.png'
import Nav06 from '@/assets/image/ico/ico-nav-list06.png'
import Nav07 from '@/assets/image/ico/ico-nav-list07.png'
import Nav08 from '@/assets/image/ico/ico-nav-list08.png'
import Nav09 from '@/assets/image/ico/ico-nav-list09.png'
import Messages from '@/assets/image/ico/ico-messages-menu.png'
import Alarm from '@/assets/image/ico/ico-bell-menu.png'
import SysLogo01 from '@/assets/image/etc/etc-sys01.svg'
import SysLogo02 from '@/assets/image/etc/etc-sys02.svg'
import { Mail } from "lucide-react"

const Header = (mtype) => {

	// console.log('globalMode', globalMode)
	// console.log('isHovered', isHovered)
	const [menufst, setMenufst] = useState("/");
	const [membertype, setMemberType] = useState("");
	const [isMenu, setIsMenu] = useState(false);
	const [contract, setContract] = useState("N");
	const handleMenu = () => {
		setIsMenu(!isMenu);
	}

	//console.log(mtype);

	//const membertype = mtype.membertype;	
	
	useEffect(() => {
		const token = getToken();
		if(token !== null){
			const local_token = JSON.parse(token);

			if(local_token.adchk === 'Y'){
				setMenufst("/admin/main");
			}

			axios({
				method: 'get',
				url: process.env.REACT_APP_API_INFOCHECK,
				mode: "cors",
				credentials: "same-origin",
				headers: {
					Authorization: "Bearer " + local_token.access_token
				},
			}).then((res) => {
				if(res.data.code === 200){
					setMemberType(res.data.profile.mtype);
					setContract(res.data.contract);
				}
			}).catch((err) => {	
				console.log("에러", err);
			})
		}else{
			const pathname = window.location.pathname;
			if((pathname === '/admin/main')){
				window.location.href="/admin";
			}
		}
	}, []);

	if(membertype === 'AD' || membertype === 'JM' || membertype === 'SM'){
		return (
			<header className={`${isMenu?Style.active:''}`}>
				<div className={Style.toggle}>
					<button type="button" onClick={handleMenu}><img src={Menu}/></button>
				</div>
				<div className={Style.rela}>
				{/* Alarm */}
					<div className={Style.ltItem}>
						<ul>
							<li>
								{menufst === "/" ?
								<a href="/"><button type="button"><img src={Home}/><span>HOME</span></button></a>
								:
								<a href="/admin/main"><button type="button"><img src={Home}/><span>HOME</span></button></a>
								}
							</li>
						</ul>
					</div>
					<div className={Style.rtItem}>
					{membertype === 'AD' ? 
						<ul>
							<li>
								<a href="/admin/space/home"><button type="button"><img src={Nav01}/><span>공간</span></button></a>
							</li>
							<li>
								<a href="/admin/space/dashboard"><button type="button"><img src={Nav02}/><span>비용</span></button></a>
							</li>
							<li>
								<a href="/admin/userList"><button type="button"><img src={Nav03}/><span>입주자</span></button></a>
							</li>
							<li>
								<a href="/admin/notice"><button type="button"><img src={Nav04}/><span>공지</span></button></a>
							</li>
							<li>
								<a href="/admin/communication"><button type="button"><img src={Nav05}/><span>1:1</span></button></a>
							</li>
							<li>
								<a href="/admin/calendar"><button type="button"><img src={Nav06}/><span>일정</span></button></a>
							</li>
							<li>
								<a href="/admin/space/reqList"><button type="button"><img src={Nav07}/><span>시설</span></button></a>
							</li>
							<li>
								<a href="/admin/house/list"><button type="button"><img src={Nav08}/><span>주택</span></button></a>
							</li>
							<li>
								<a href="/admin/teamList"><button type="button"><img src={Nav09}/><span>관리자</span></button></a>
							</li>
							<li>
								<a href="/admin/messages/sendMessages"><button type="button"><img src={Messages}/><span>메시지</span></button></a>
							</li>
							{/*}
							<li>
								<a href="/admin/edocu"><button type="button"><img src={Messages}/><span>전자문서설정</span></button></a>
							</li>
							{*/}
						</ul>
						:
						membertype === 'JM' ? 
						<ul>
							<li>
								<a href="/admin/space/home"><button type="button"><img src={Nav01}/><span>공간</span></button></a>
							</li>
							<li>
								<a href="/admin/space/dashboard"><button type="button"><img src={Nav02}/><span>비용</span></button></a>
							</li>
							<li>
								<a href="/admin/userList"><button type="button"><img src={Nav03}/><span>입주자</span></button></a>
							</li>
							<li>
								<a href="/admin/notice"><button type="button"><img src={Nav04}/><span>공지</span></button></a>
							</li>
							<li>
								<a href="/admin/communication"><button type="button"><img src={Nav05}/><span>1:1</span></button></a>
							</li>
							<li>
								<a href="/admin/calendar"><button type="button"><img src={Nav06}/><span>일정</span></button></a>
							</li>
							<li>
								<a href="/admin/space/reqList"><button type="button"><img src={Nav07}/><span>시설</span></button></a>
							</li>
						</ul>
						:
						<ul>
							<li>
								<a href="/admin/notice"><button type="button"><img src={Nav04}/><span>공지</span></button></a>
							</li>
							<li>
								<a href="/admin/communication"><button type="button"><img src={Nav05}/><span>1:1</span></button></a>
							</li>
							<li>
								<a href="/admin/calendar"><button type="button"><img src={Nav06}/><span>일정</span></button></a>
							</li>
							<li>
								<a href="/admin/space/reqList"><button type="button"><img src={Nav07}/><span>시설</span></button></a>
							</li>
						</ul>
					}
					</div>
					<div className={Style.etcLogo}>
						<img src={SysLogo01} className={Style.SysLogo01}/>
						<img src={SysLogo02} className={Style.SysLogo02}/>
					</div>
				</div>
			</header>
		)

	}else if(membertype === "TA"){
		return (
			<header className={`${isMenu?Style.active:''}`}>
				<div className={Style.toggle}>
					<button type="button" onClick={handleMenu}><img src={Menu}/></button>
				</div>
				<div className={Style.rela}>
				{/* Alarm */}
					<div className={Style.ltItem}>
						<ul>
							<li>
								{menufst === "/" ?
								<a href="/"><button type="button"><img src={Home}/><span>HOME</span></button></a>
								:
								<a href="/admin/main"><button type="button"><img src={Home}/><span>HOME</span></button></a>
								}
							</li>
						</ul>
					</div>
					<div className={Style.rtItem}>
						<ul>
							<li>
								<a href="/admin/companyList"><button type="button"><img src={Nav09}/><span>계정</span></button></a>
							</li>
							<li>
								<a href="/admin/calendar"><button type="button"><img src={Nav06}/><span>일정</span></button></a>
							</li>
						</ul>
					</div>
					<div className={Style.etcLogo}>
						<img src={SysLogo01} className={Style.SysLogo01}/>
						<img src={SysLogo02} className={Style.SysLogo02}/>
					</div>
				</div>
			</header>
		)

	}else if(membertype === 'RS'){
		return (
			<header className={`${isMenu?Style.active:''}`}>
				<div className={Style.toggle}>
					<button type="button" onClick={handleMenu}><img src={Menu}/></button>
				</div>
				<div className={Style.rela}>
					<div className={Style.etcLogo}>
						<img src={SysLogo01} className={Style.SysLogo01}/>
						<img src={SysLogo02} className={Style.SysLogo02}/>
					</div>

					<div className={Style.ltItem}>
						<ul>
							<li>
								<a href="/"><button type="button"><img src={Home}/><span>HOME</span></button></a>
							</li>
						</ul>
					</div>
					
					<div className={Style.rtItem}>
						<ul>
							<li>
								<a href="/notice"><button type="button"><img src={Nav04}/><span>공지</span></button></a>
							</li>
							<li>
								<a href="/communication"><button type="button"><img src={Nav05}/><span>1:1</span></button></a>
							</li>
							{contract === "Y" ?
								<li>
									<a href="/contract"><button type="button"><img src={Nav05}/><span>계약</span></button></a>
								</li>
								: ""
							}
						</ul>
					</div>

				</div>
			</header>
		)
	}else{
		return (
			<header className={`${isMenu?Style.active:''}`}>
				<div className={Style.toggle}>
					<button type="button" onClick={handleMenu}><img src={Menu}/></button>
				</div>
				<div className={Style.rela}>
					<div className={Style.etcLogo}>
						<img src={SysLogo01} className={Style.SysLogo01}/>
						<img src={SysLogo02} className={Style.SysLogo02}/>
					</div>

					<div className={Style.ltItem}>
						<ul>
							<li>
								<a href="/"><button type="button"><img src={Home}/><span>HOME</span></button></a>
							</li>
						</ul>
					</div>
					<div className={Style.rtItem}>
						<ul>
						</ul>
					</div>
					
				</div>
			</header>
		)
	}
}

export default Header
